<template>
  <ul>
    <li
      v-for="item in items"
      :key="item.id"
      class="bfy-level"
    >
      <div class="bfy-level-left">
        <car-list-item :item="item.car" />
        <driver-list-item :item="item.driver" />
        <small v-if="isComplate" class="tag is-abbr-like">{{ item.road_time }}</small>
        <small v-if="isComplate" class="tag is-abbr-like">{{ item.road_distance }}km</small>
      </div>

      <div class="bfy-level-right">
        <b-button type="is-info" size="is-circle is-small" tag="a"
                  class="transfer-button" outlined
                  :href="getLink(item.transfer_id)" target="_blank"
                  @click.prevent="click(item.transfer_id)"
        >
          #{{ item.transfer_id }}
        </b-button>
      </div>
    </li>
  </ul>
</template>
<script>
import DriverListItem from '@/components/Board/DriverListItem'
import CarListItem from '@/components/Board/CarListItem'

export default {
  name: 'CarDriverList',
  components: { DriverListItem, CarListItem},
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isComplate: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click (transerId) {
      this.$parent.$parent.showTransfer(transerId)
    },
    getLink (transerId) {
      return this.$router.resolve({
        name: 'transfers.show',
        params: { id: transerId},
      }).href;
    }
  }
}
</script>
