<template>
  <small :id="`car-tooltip-target-${_uid}`" class="tag is-abbr-like plate">
    {{ item.plate }}

    <tooltip :target="`car-tooltip-target-${_uid}`" triggers="hover focus" noninteractive custom-class="board-tooltip">
      Marka: {{ item.brand }} <br>
      Model: {{ item.model }} <br>
      Toplam Sefer: {{ item.total_voyage }} <br>
      Toplam Süre: {{ item.total_time | humanTime }} <br>
      Toplam Km: {{ item.total_distance }} <br>
    </tooltip>
  </small>
</template>
<script>
import { BTooltip as Tooltip } from 'bootstrap-vue'

export default {
  name: 'CarListItem',
  components: { Tooltip },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
